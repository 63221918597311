<template>
  <v-container class="px-6 d-block" fluid>
    <div class="d-flex align-center mb-6">
      <v-icon size="15" color="#4ab762">mdi-home</v-icon>
      <v-breadcrumbs :items="breadcrumbs" class="pa-0 ml-2">
        <template v-slot:divider>
          <v-icon>mdi-chevron-right</v-icon>
        </template>
      </v-breadcrumbs>
    </div>

    <v-row>
      <v-col cols="12">
        <v-card
          class="rounded-lg"
          style="box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px"
        >
          <v-card-title
            class="subtitle-1 grey--text text--darken-2 font-weight-bold"
            >Data Kategori Koleksi Kelas</v-card-title
          >
          <v-card-text class="px-0 py-0">
            <div class="px-4 d-flex justify-space-between">
              <v-btn-toggle dense class="mb-2">
                <v-btn
                  color="#2ecc71"
                  class="text-none"
                  text
                  small
                  :disabled="loading"
                  @click="dialog.collection = true"
                >
                  <v-icon size="16" color="#2ecc71"> mdi-plus-box </v-icon>
                  <span class="hidden-sm-and-down ml-1">Tambah</span>
                </v-btn>

                <v-btn
                  color="#7f8c8d"
                  :disabled="loading || selected.collection.length <= 0"
                  class="text-none"
                  text
                  small
                  @click="
                    setForm();
                    dialog.collection = true;
                  "
                >
                  <v-icon size="16" color="#7f8c8d"> mdi-pencil-box </v-icon>
                  <span class="hidden-sm-and-down ml-1">Ubah</span>
                </v-btn>

                <v-btn
                  color="#3498db"
                  class="text-none"
                  :disabled="loading || selected.collection.length <= 0"
                  text
                  small
                  @click="dialog.detail = true"
                >
                  <v-icon size="16" color="#3498db"> mdi-information </v-icon>
                  <span class="hidden-sm-and-down ml-1">Detail</span>
                </v-btn>

                <v-btn
                  color="#e74c3c"
                  class="text-none"
                  :disabled="loading || selected.collection.length <= 0"
                  text
                  small
                  @click="
                    setForm();
                    dialog.confirmDelete = true;
                  "
                >
                  <v-icon size="16" color="#e74c3c">
                    mdi-delete-forever
                  </v-icon>
                  <span class="hidden-sm-and-down ml-1">Hapus</span>
                </v-btn>
              </v-btn-toggle>

              <v-btn-toggle dense class="mb-2">
                <v-btn
                  color="#27ae60"
                  class="text-none"
                  text
                  small
                  :disabled="loading"
                  @click="exportData"
                >
                  <v-icon size="16" color="#27ae60"> mdi-file-excel </v-icon>
                  <span class="hidden-sm-and-down ml-1">Export Excel</span>
                </v-btn>
              </v-btn-toggle>
            </div>
            <v-data-table
              v-model="selected.collection"
              :headers="headers.collection"
              :items="dataGrid.collection"
              :loading="loading"
              :options.sync="options.collection"
              :server-items-length="totalData.collection"
              loading-text="Sedang memuat"
              :no-data-text="'Data belum ada'"
              no-results-text="Data belum ada"
              sort-by-text="Urutkan berdasarkan"
              :items-per-page="10"
              height="calc(100vh - 340px)"
              fixed-header
              :footer-props="footerProps"
              item-key="id"
              flat
              single-select
              show-select
              class="elevation-0 custom-grid rounded-lg"
            >
              <template v-slot:[`item.name`]="{ item }">
                <v-list-item class="py-0 px-0">
                  <v-list-item-avatar
                    tile
                    size="32"
                    class="rounded mr-2"
                    color="#f7f7f7"
                  >
                    <span v-if="item.img_url == ''">{{
                      item.name | initial
                    }}</span>
                    <v-img v-else :src="item.img_url"></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content class="py-0" style="max-width: 220px">
                    <v-list-item-title
                      class="subtitle-2 grey--text text--darken-1"
                      >{{ item.name }}</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </template>

              <template v-slot:[`item.count`]="{ item }">
                <v-chip
                  small
                  class="ma-2 font-weight-bold"
                  label
                  style="
                    color: rgb(52 73 94);
                    background-color: rgb(52 73 94 / 10%);
                  "
                >
                  {{ item.count }} Kelas
                </v-chip>
              </template>

              <template v-slot:[`item.created_datetime`]="{ item }">
                {{ item.created_datetime | datetime }}
              </template>

              <template v-slot:[`item.last_update_datetime`]="{ item }">
                {{ item.last_update_datetime | datetime }}
              </template>

              <template v-slot:[`footer.page-text`]="props">
                Menampilkan {{ props.pageStart }} - {{ props.pageStop }} data
                dari total {{ props.itemsLength }} data
                <v-btn text class="ml-4 text-none" small @click="fetchData"
                  ><v-icon left>mdi-sync</v-icon> Perbarui</v-btn
                >
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- FORM -->
    <v-dialog
      v-model="dialog.collection"
      v-if="dialog.collection"
      persistent
      no-click-animation
      max-width="450"
      transition="slide-x-reverse-transition"
      content-class="my-custom-dialog"
      scrollable
    >
      <v-card tile>
        <v-card-title
          class="subtitle-1 px-4 py-2 d-block"
          style="color: #4ab762"
        >
          {{ form.id > 0 ? "Ubah" : "Tambah" }} Kategori Koleksi Kelas
          <div class="caption grey--text text--darken-1">
            Lengkapi form dibawah ini
          </div>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: calc(100vh - 118px)" class="py-4 px-4">
          <v-form
            ref="form-collection"
            v-model="valid"
            lazy-validation
            @keyup.native.enter="doSave"
          >
            <ImageUpload v-model="form.img_url" class="mb-4" />

            <div class="subtitle-2">
              Nama Kategori
              <small style="color: #e74c3c"><i>*wajib diisi</i></small>
            </div>
            <v-text-field
              dense
              solo
              autocomplete="off"
              color="#4ab762"
              type="text"
              placeholder="Tulis disini"
              class="rounded-lg mb-3"
              :disabled="loading"
              v-model="form.name"
              :error-messages="validation.name"
              @keyup="validation.name = ''"
              :rules="[
                (v) => !!v || `Nama kategori tidak boleh kosong`,
                (v) =>
                  (v && v.length <= 50) || 'Nama kategori maksimal 50 karakter',
              ]"
            >
              <template v-slot:message="{ message }">
                <v-tooltip top max-width="250" color="#e74c3c">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      size="22"
                      class="mr-2 ml-n2"
                      color="#e74c3c"
                      v-bind="attrs"
                      v-on="on"
                      >mdi-alert-circle-outline</v-icon
                    >
                  </template>
                  <span>{{ message }}</span>
                </v-tooltip>
              </template>
            </v-text-field>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-space-between px-4">
          <v-btn
            rounded
            depressed
            class="text-none white--text text--lighten-3 flex-grow-1"
            color="#4ab762"
            @click="doSave"
          >
            <v-icon left>mdi-check-circle-outline</v-icon
            >{{ form.id > 0 ? "Simpan" : "Tambahkan" }} Kategori
          </v-btn>
          <v-btn
            rounded
            depressed
            outlined
            class="text-none"
            color="#e74c3c"
            width="130"
            @click="
              dialog.collection = false;
              formReset();
              $refs['form-collection'].resetValidation();
              validationReset();
            "
            >Batal</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialog.detail"
      v-if="dialog.detail"
      max-width="450"
      scrollable
    >
      <v-card tile>
        <v-card-title
          class="subtitle-1 px-4 py-2 d-block"
          style="color: #4ab762"
        >
          Detail Kategori Koleksi Kelas
          <div class="caption grey--text text--darken-1">
            Informasi Data Kategori Koleksi Kelas
          </div></v-card-title
        >
        <v-divider></v-divider>
        <v-card-text
          style="height: calc(100vh - 118px)"
          class="py-4 px-4"
          v-if="selected.collection.length > 0"
        >
          <div class="d-flex flex-column justify-center align-center">
            <v-avatar tile class="rounded-lg mb-2" size="120" color="#ecf0f1">
              <span
                v-if="selected.collection[0].img_url == ''"
                style="font-size: 42px"
                >{{ selected.collection[0].name | initial }}</span
              >
              <v-img v-else :src="selected.collection[0].img_url"></v-img>
            </v-avatar>
            <v-chip
              x-small
              class="ma-2 font-weight-bold"
              label
              style="
                color: rgb(52 73 94);
                background-color: rgb(52 73 94 / 10%);
              "
            >
              {{ selected.collection[0].count }} Kelas
            </v-chip>
          </div>

          <div
            class="
              subtitle-2
              font-weight-bold
              grey--text
              text--darken-1 text-center text-decoration-underline
              mt-5
            "
          >
            Informasi Kategori Koleksi Kelas
          </div>

          <div class="my-3">
            <div class="caption">Nama</div>
            <div class="subtitle-2 font-weight-bold">
              {{ selected.collection[0].name | emptyData }}
            </div>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-space-between px-4">
          <v-btn
            color="#e74c3c"
            outlined
            depressed
            rounded
            @click="dialog.detail = false"
            class="text-none flex-grow-1"
          >
            Tutup
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialog.confirmDelete"
      persistent
      no-click-animation
      max-width="380"
      content-class="rounded-lg"
    >
      <v-card
        class="rounded-lg"
        v-if="
          selected.collection.length > 0 && selected.collection[0].count <= 0
        "
      >
        <v-card-title
          class="text-h6 font-weight-bold grey--text text--darken-2"
        >
          <v-icon left>mdi-information</v-icon>Konfirmasi Hapus
        </v-card-title>
        <v-card-text style="min-height: 80px"
          >Apakah yakin akan menghapus kategori koleksi kelas
          <b>{{ form.name }}</b
          >?
        </v-card-text>
        <v-card-actions class="justify-space-between px-4">
          <v-btn
            color="#4ab762"
            depressed
            rounded
            @click="doDelete"
            class="text-none white--text text--lighten-3 flex-grow-1"
          >
            <v-icon left>mdi-check-circle-outline</v-icon>
            Ya, hapus
          </v-btn>
          <v-btn
            color="#e74c3c"
            outlined
            depressed
            rounded
            @click="
              dialog.confirmDelete = false;
              formReset();
              validationReset();
            "
            class="text-none"
            width="100"
          >
            Batal
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card
        class="rounded-lg"
        v-if="selected.collection.length && selected.collection[0].count > 0"
      >
        <v-card-title
          class="text-h6 font-weight-bold grey--text text--darken-2"
        >
          <v-icon left>mdi-information</v-icon>Peringatan!
        </v-card-title>
        <v-card-text style="min-height: 80px">
          Tidak dapat menghapus kategori koleksi kelas
          <b>{{ selected.collection[0].name }}</b> karena memiliki
          <b>{{ selected.collection[0].count }} Kelas</b>.
        </v-card-text>
        <v-card-actions class="justify-space-between px-4">
          <v-btn
            color="#e74c3c"
            block
            outlined
            depressed
            rounded
            @click="
              dialog.confirmDelete = false;
              formReset();
              validationReset();
            "
            class="text-none"
          >
            Tutup
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  name: "Collection",

  components: {
    ImageUpload: () =>
      import(/* webpackChunkName: "ImageUpload" */ "@/components/ImageUpload"),
  },

  data: () => ({
    breadcrumbs: [
      {
        text: "Dashboard",
        disabled: false,
        href: "/",
      },
      {
        text: "Kategori Koleksi Kelas",
        disabled: true,
        href: "/collection",
      },
    ],

    loading: false,
    dialog: {
      collection: false,
      confirmDelete: false,
      detail: false,
    },
    selected: {
      collection: [],
    },
    headers: {
      collection: [
        {
          text: "NAMA KOLEKSI",
          width: "300",
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          text: "JML KELAS",
          width: "120",
          align: "center",
          sortable: true,
          value: "count",
        },
        {
          text: "TGL BUAT",
          width: "180",
          align: "center",
          sortable: true,
          value: "created_datetime",
        },
        {
          text: "TERAKHIR DIUBAH",
          width: "180",
          align: "center",
          sortable: true,
          value: "last_update_datetime",
        },
      ],
    },
    dataGrid: {
      collection: [],
    },
    options: {
      collection: {},
    },
    totalData: {
      collection: 0,
    },
    footerProps: {
      "show-current-page": true,
      "show-first-last-page": true,
      "items-per-page-options": [10, 15, 30, 50, 100],
      "items-per-page-text": "Data per halaman",
      "page-text": "{0} - {1} dari total {2}",
    },

    valid: true,
    form: {
      id: "",
      name: "",
      img_url: "",
    },
    validation: {
      id: "",
      name: "",
      img_url: "",
    },
  }),

  watch: {
    "options.collection": {
      async handler() {
        await this.fetchData();
      },
      deep: true,
    },
  },

  methods: {
    formReset() {
      Object.assign(this.form, {
        id: "",
        name: "",
        img_url: "",
      });
    },
    validationReset() {
      Object.assign(this.validation, {
        id: "",
        name: "",
        img_url: "",
      });
    },
    setForm() {
      let selected = this.selected.collection[0];
      Object.assign(this.form, {
        id: selected.id,
        name: selected.name,
        img_url: selected.img_url,
      });
    },

    async doSave() {
      let isValid = this.$refs["form-collection"].validate();

      if (isValid) {
        this.$store.state.overlay.show = true;
        this.$store.state.overlay.text =
          "Menyimpan data kategori koleksi kelas...";

        let url = "admin/course_category";
        if (this.form.id > 0) {
          url = "admin/course_category/update";
        }

        let response = await this.$post(url, this.form);

        this.$store.state.overlay.show = false;

        if (response.status == 200) {
          this.$store.state.snackbar = {
            show: true,
            text: "Berhasil menyimpan data kategori koleksi kelas",
            color: "#2ecc71",
          };
          this.dialog.collection = false;

          this.formReset();
          this.$refs["form-collection"].resetValidation();

          this.validationReset();

          this.selected.collection = [];
          this.fetchData();
        } else if (response.status == 412) {
          this.$store.state.snackbar = {
            show: true,
            text: "Cek kembali form Anda",
            color: "#e74c3c",
          };

          Object.assign(this.validation, response.results.data);
        } else if (response.status == 400) {
          this.$store.state.snackbar = {
            show: true,
            text: response.message,
            color: "#e74c3c",
          };
        }
      }
    },
    async doDelete() {
      this.$store.state.overlay.show = true;
      this.$store.state.overlay.text = "Menghapus kategori koleksi kelas...";

      let response = await this.$post(
        "admin/course_category/delete",
        this.form
      );

      this.$store.state.overlay.show = false;

      if (response.status == 200) {
        this.$store.state.snackbar = {
          show: true,
          text: "Berhasil menghapus kategori koleksi kelas",
          color: "#2ecc71",
        };
        this.dialog.confirmDelete = false;

        this.selected.collection = [];
        this.fetchData();
      } else if (response.status == 412) {
        this.$store.state.snackbar = {
          show: true,
          text: "Cek kembali form Anda",
          color: "#e74c3c",
        };
      } else if (response.status == 400) {
        this.$store.state.snackbar = {
          show: true,
          text: response.message,
          color: "#e74c3c",
        };
      }
    },

    async fetchData() {
      this.loading = true;

      let sign =
        this.options.collection.sortDesc && this.options.collection.sortDesc[0]
          ? "-"
          : "";

      let params = {
        page: this.options.collection.page,
        limit: this.options.collection.itemsPerPage,
        sort: "-id",
      };

      if (
        this.options.collection.sortBy.length > 0 &&
        this.options.collection.sortDesc.length > 0
      ) {
        params.sort =
          sign +
          (this.options.collection.sortBy && this.options.collection.sortBy[0]);
      }

      let response = await this.$get("admin/course_category", params);

      this.loading = false;

      if (response.status == 200) {
        this.dataGrid.collection = response.results.data;
        this.totalData.collection = parseInt(
          response.results.pagination.total_data
        );
      } else {
        this.$store.state.snackbar = {
          show: true,
          text: "Gagal memuat data.",
          color: "#f39c12",
        };
      }
    },

    async exportData() {
      this.$store.state.overlay.show = true;
      this.$store.state.overlay.text = "Mengexport data...";

      let sign =
        this.options.collection.sortDesc && this.options.collection.sortDesc[0]
          ? "-"
          : "";

      let params = {
        page: this.options.collection.page,
        limit: this.options.collection.itemsPerPage,
        sort: "-id",
      };

      if (
        this.options.collection.sortBy.length > 0 &&
        this.options.collection.sortDesc.length > 0
      ) {
        params.sort =
          sign +
          (this.options.collection.sortBy && this.options.collection.sortBy[0]);
      }

      let response = await this.$get(
        "admin/course_category/export_xls",
        params
      );

      this.$store.state.overlay.show = false;
      this.$store.state.overlay.text = "";

      if (response.status == 200) {
        this.$store.state.snackbar = {
          show: true,
          text: "Berhasil mengexport data.",
          color: "#2ecc71",
        };

        window.open(response.results.data.export_url, "_blank");
      } else {
        this.$store.state.snackbar = {
          show: true,
          text: "Gagal mengexport data.",
          color: "#f39c12",
        };
      }
    },
  },
};
</script>

<style>
</style>